import { render, staticRenderFns } from "./Chartpage.vue?vue&type=template&id=280f2e44&scoped=true"
import script from "./Chartpage.vue?vue&type=script&lang=js"
export * from "./Chartpage.vue?vue&type=script&lang=js"
import style0 from "./Chartpage.vue?vue&type=style&index=0&id=280f2e44&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "280f2e44",
  null
  
)

export default component.exports